import CryptoJs from 'crypto-js'
export default {
  /**
   * 加密
   */
  encrypt(word,dynamic) {
    let key = CryptoJs.enc.Utf8.parse(dynamic);
    let srcs = CryptoJs.enc.Utf8.parse(word);
    let encrypted = CryptoJs.AES.encrypt(srcs, key, {
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
    });
    return encrypted.toString();
  },

  /**
   * 解密
   */
  decrypt(word,dynamic) {
    let key = CryptoJs.enc.Utf8.parse(dynamic);
    let decrypt = CryptoJs.AES.decrypt(word, key, {
      mode: CryptoJs.mode.ECB,
      padding: CryptoJs.pad.Pkcs7
    });
    return CryptoJs.enc.Utf8.stringify(decrypt).toString();
  }
}

// 需要注意的地方有几点：
// （1）加密的秘钥的长度必须是8的整数倍，如果不是，解码的结果为空；
// （2）我们加密的可能会是字符串或者一个对象，所以加密时得做判断先，方法已经写好了，注意下就行了；
// （3）实际开发肯定是前后端一起的，所以前后端统一秘钥进行加解密即可，后端采用了java的解密方法；
// （4）项目环境中，在代码编译阶段产生ES5、ES6编译冲突，需要根据具体场景解决。
